import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
// import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment'
import $ from "jquery";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Dossier",

            crudform: {
                id: this.$route.params.id_client_dossier,
                nom_dossier: "",
                nom_client:'',
                id_client: "",
                total_general_credit:0,
                total_general_debit:0,
                total_general_solde:0,
                detail:[],
            },
            tab_tresoerie: [],
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            tab_client:[],
            isDisabled:false,
            isPrint:true,
            temp_idCLient:'',
            crudformClient: {
                id: "",
                id_client: "",
                nom_client:'',
                nom_dossier: "",
            },
            crudmodaltitle:'Modification',
            Tab_Dossier:[],
        }
    },
    methods: {
        /**
         * charger liste des dossier par client
         * @constructor
         */
        LoadFolderByClient(value){
            if (value){
                this.crudform.id ='';
            }
            // this.crudform.detail = [];
            axios.get(this.BASE_URL + "/clientdossier/loadfolderbyclient/"+ this.crudform.id_client).then(response => {
                this.Tab_Dossier = response.data;
                if (this.crudform.nom_client !=this.temp_idCLient){
                    this.crudform.nom_dossier = '';
                    this.crudform.id = '';
                }
                this.calculMontantTotal();
            });
        },

        onSubmitDossier(){
            var that = this;
            var link = that.BASE_URL + "/clientdossier/editaction";
            axios.post(link, $("#formulaireDossier").serialize()).then(function(response) {
                if (response.data.error === "") {
                    console.log("Enregistrement effectué avec succès!");
                    that.closeModal();
                    that.getDossier(that.$route.params.id_client_dossier);
                    that.getClient();
                    Vue.$toast.open({
                        message: 'modification effectué avec succès!',
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    console.log("Erreur");
                    Vue.$toast.open({
                        message: response.data.error,
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        openModal() {
            axios.get(this.BASE_URL + "/clientdossier/getelement/"+this.$route.params.id_client_dossier).then(response => {
                this.crudformClient = response.data[0];
                this.$bvModal.show("crudmodal");
            });
        },

        closeModal() {
            this.$bvModal.hide("crudmodal");
        },

        printElement(){
            window.open(this.BASE_URL + "/clientdossierdetail/imprimerelement/" + this.$route.params.id_client_dossier, '_blank');
        },

        calculMontantTotal(){
            var total_generail_debit =0, total_general_credit =0;
            this.crudform.detail.forEach(value => {
                var total_debit =0,total_credit = 0,solde = 0;
                value.info.forEach(data=>{
                    total_credit+= parseFloat(data.montant_credit);
                    total_debit+= parseFloat(data.montant_debit);
                });
                value.total_debit = total_debit;
                value.total_credit = total_credit;
                solde = total_credit - total_debit;
                value.total_solde = solde;
                total_generail_debit += total_debit;
                total_general_credit += total_credit;
            })

            this.crudform.total_general_credit = total_general_credit;
            this.crudform.total_general_debit = total_generail_debit;
            this.crudform.total_general_solde = total_general_credit - total_generail_debit;
            this.isPrint = parseFloat(this.crudform.total_general_solde) > 0  ? false : true;
        },

        saveRow(index_detail, index_info,id_info){
            console.log(index_detail);
            console.log(index_info);
            console.log(id_info);
            let formulaire = new FormData();
            formulaire.append('data',JSON.stringify(this.crudform));
            formulaire.append('index_detail',index_detail);
            formulaire.append('index_info',index_info);
            formulaire.append('id_info',id_info);

            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment enregistrer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        this.isDisabled = true;
                        var link = that.BASE_URL + "/clientdossierdetail/saverow" ;

                        axios.post(link,formulaire).then((response)=> {
                            console.log('reponse', response.data.element_info)
                            that.isDisabled = false;
                            if (response.data.error === "") {
                                this.$set(this.crudform.detail[index_detail].info, index_info, response.data.element_info);
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        pushInfo(index_detail){
            this.crudform.detail[index_detail].info.push({
                id:'',
                id_client_dossier_detail:'',
                date_info:moment().format('YYYY-MM-DD'),
                date_info_formatted:moment().format('DD/MM/YYYY'),
                libeller_info:'',
                montant_credit:0,
                montant_debit:0,
                reference_info:moment().format('YYYYMMDDHHmmss')
            })
        },

        spliceInfo(index_detail, index_info,id_info){

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        if (id_info <=0){
                            this.crudform.detail[index_detail].info.splice(index_info,1);
                            this.calculMontantTotal();
                        }else{
                            axios.get(this.BASE_URL+"/clientdossierdetail/deleteinfo/"+id_info).then(response=>{
                                console.log(response)
                                this.crudform.detail[index_detail].info.splice(index_info,1);
                                this.calculMontantTotal();
                            })
                        }

                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })
        },

        getDossier(id){
            axios.get(this.BASE_URL + "/clientdossier/get/"+id).then(response => {
                this.crudform = response.data[0];
                this.temp_idCLient = response.data[0].nom_client;
                // if (this.crudform.detail.length <=0){
                    // this.getTresorerieParActiviter();
                    this.LoadFolderByClient(false);
                // }
            });
        },

        getTresorerieParActiviter(){
            axios.get(this.BASE_URL + "/tresoreries/gettresorerieparactiviter").then(response => {
                const currentDate = moment().format('YYYYMMDDHHmmss');

                response.data.forEach((value,index)=>{
                    const newReference = moment(currentDate, 'YYYYMMDDHHmmss').add(index, 'seconds').format('YYYYMMDDHHmmss');
                    this.crudform.detail.push({
                        id_users:'',
                        id_client_dossier:this.$route.params.id_client_dossier,
                        id_tresorerie:value.id,
                        nom_tresorerie:value.nom_tresorerie,
                        total_debit:0,
                        total_credit:0,
                        total_solde:0,
                        info:[{
                            id:'',
                            id_client_dossier_detail:'',
                            date_info:'',
                            date_info_formatted:moment().format('DD/MM/YYYY'),
                            libeller_info:'',
                            montant_credit:0,
                            montant_debit:0,
                            reference_info:newReference
                        }],
                    })
                });
                console.log('tresorerie',this.crudform)
            });
        },

        getClient(){
            axios.get(this.BASE_URL + "/clients/getall/").then(response => {
                this.tab_client = response.data;
            });
        },

        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        this.isDisabled = true;
                        var link = that.BASE_URL + "/clientdossierdetail/addaction" ;
                        let formulaire = new FormData();
                        formulaire.append('data',JSON.stringify(this.crudform))

                        axios.post(link,formulaire).then(function(response) {
                            that.isDisabled = false;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },


    },
    watch: {

    },
    computed: {

    },
    created: function() {

    },
    mounted: function() {

        this.getDossier(this.$route.params.id_client_dossier)
        this.getClient();
    }
}
